<template>
  <div class="m-home">
    <TopBar :type="1" :logo="logo" />
    <router-view />
    <div class="deep_cont_box">
      <div class="cont_info">
        <div>
          <img src="@/assets/m/newIndex/phone_icon.png" alt="">
          <div class="text flex">
            <p>热线电话：</p><span>400-0123-823</span>
          </div>
        </div>
        <div class="text">
          <p> 技术支持：中工科创</p>
        </div>
      </div>
      <div class="code_text">
          Copyright © 2015-{{new Date().getFullYear()}} kskstudy.com All Rights Reserved 京ICP备16012224号-3
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from "@/m/common/topBar.vue";
export default {
  components: {
    TopBar
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.deep_cont_box{
  width: 100%;
  height: 212px;
  background: #292F36;
  box-sizing: border-box;
  padding: 48px 40px 0;
  .cont_info{
    display: flex;
    justify-content: space-between;
    >div{
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      img{
        width: 40px;
        height: 40px;
      }
      span{
        color: #1E84FF;
        opacity: 1;
      }
    }
    .text{
      p{
        opacity: .5;
      }
    }
  }
  .code_text{
    font-size: 24px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    text-align: center;
    margin-top: 28px;
    opacity: .5;
    line-height: 40px;
  }
}
</style>